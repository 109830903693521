import React, { Fragment, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import HomePage from './components/HomePage';
// import Footer from './components/Footer';

const App = () => {
    const detectKey = useCallback(e => {
        switch (e.keyCode) {
            case 48:
                window.location.href = '/';
                break;
            // case 49:
            //     window.location.href = '/projects';
            //     break;
            // case 50:
            //     window.location.href = '/opensource';
            //     break;
            // case 51:
            //     window.location.href = '/skills';
            //     break;
            // case 52:
            //     window.location.href = '/resume';
            //     break;
            // case 53:
            //     window.location.href = '/lab';
            //     break;
            // case 54:
            //     window.location.href = '/reads';
            //     break;
            // case 55:
            //     window.location.href = '/connect';
            //     break;
            // case 56:
            //     window.location.href = '/contact';
            //     break;
            // case 57:
            //     window.location.href = '/support';
            //     break;
            default:
                break;
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', detectKey);
        // cleanup eventListener
        return () => document.removeEventListener('keydown', detectKey);
    }, [detectKey]);

    return (
        <Fragment>
            <Router>
                <Routes>
                    <Route exact path = "/" element = { <HomePage /> }></Route>
                </Routes>
                {/* <Footer /> */}
            </Router>
        </Fragment>
    );
};

export default App;
